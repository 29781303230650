
.animated-logo{
  display: inline-block;
  border-radius:100%;
  /* border:10px solid #00d8ff; */
  animation-name:rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

}

/* animate pulse */

@keyframes rotate{

  100%{
    transform:rotate(360deg);
  }
}


.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}


.c-office-overview__map {
  position: fixed;
  border-radius: 36px;
  margin-top: 16px;
  margin-bottom: 0;
}

.c-office-overview__map-selected-info__close {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
}
